<template>
  <div class="details">
    <div v-if="loading || !baseetymvar">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle
              v-bind:title="baseetymvar.form"
              :pretitle="'Base Etym Var details'"
            />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button
                  v-if="$store.getters.checkRole('legam.scripta.baseetymvar.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button
                  @click="editMode = true"
                  v-if="$store.getters.checkRole('legam.scripta.baseetymvar.modify')"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="
                    !$store.getters.checkRole('legam.scripta.baseetymvar.modify') &&
                    $store.getters.checkRole('legam.scripta.baseetymvar.delete')
                  "
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Form</h6>
                  <b-form-input
                    id="form"
                    v-model="baseetymvar.form"
                    placeholder="Form"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="baseetymvar.form"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="comment"
                    v-model="baseetymvar.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="baseetymvar.comment"></span>
                </b-col>
                <b-col>
                  <h6>Heads</h6>
                  <router-link
                    v-for="head in baseetymvar.heads"
                    :key="head.id"
                    :class="badge"
                    :to="{ name: 'HeadDetails', params: { id: head.id }}"
                  >
                    <b-badge variant="info" class="mr-1">{{ head.form }}</b-badge>
                  </router-link>
                </b-col>
              </b-row>

              <hr>

            </article>
          </b-col>
        </b-row>
        <div class="mt-4">
          <h6>Logs</h6>
          <ChangeLogsTable
            :objectType="'baseetymvar'"
            :objectId="id"
            :key="'logs' + index"
          />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
* >>> th {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
</style>

<script>
import { mapState } from "vuex";
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import ChangeLogsTable from "@/components/ChangeLogsTable.vue";
import config from '@/config.js'

export default {
  name: "BaseEtymVarDeatils",
  data() {
    return {
      config: config,
      id: this.$route.params.id,
      loading: true,
      editMode: false,
      index: 0,
    };
  },
  components: {
    PageTitle,
    ChangeLogsTable,
  },
  computed: {
    ...mapState({
      baseetymvar: (state) => state.baseetymvar.baseetymvar,
    }),
  },
  mounted() {
    this.$store.dispatch("loadBaseetymvar", this.id);
  },
  watch: {
    id() {
      this.loading = true;
      this.$store.dispatch("loadBaseetymvar", this.id);
    },
    baseetymvar() {
      this.loading = false;
    },
  },
  methods: {
    cancelEdit() {
      this.editMode = false;
      this.$store.dispatch("loadBaseetymvar", this.id);
      this.$store.dispatch("addNotification", {
        type: "warning",
        text: `Changes dismissed`,
      });
    },
    saveEdit() {
      this.editMode = false;
      this.$store.dispatch("saveBaseetymvar", this.baseetymvar).then(() => {
        this.$store.dispatch("addNotification", {
          type: "success",
          text: `Changes saved`,
        });
        this.index++;
      });
    },
    deleteItem() {
      this.$bvModal.msgBoxConfirm("Are you sure?").then((value) => {
        if (value) {
          this.$store.dispatch("deleteBaseetymvar", this.baseetymvar).then(() => {
            router.push("/baseetymvars");
          });
        }
      });
    },
  },
};
</script>
